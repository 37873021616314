/*
 * logo
**/

.hamburger{
	top: 6px;
    position: relative;	
    padding-left: 0 !important;

    &.is-active{
		
    	.hamburger-inner{
			background-color: #FFF !important;

	    	&:before,
	    	&:after{
	    		background-color: #FFF !important;
	    	}
	    }
    }
}

.hamburger-text{
	&.is-active{
		color: $white;
	}
}

.hamburger-box{
    transform: scale(0.75);
}

.menu{
	margin-top: 35px;

	span{
		font-weight: 700;
		font-size: 20px;
	}
}

.menu-overlay{
	background-image: url('images/content-background.jpg');
	background-size: cover;
	position:absolute;
	top: 0;
	left: 0;
	opacity: 0;
	display: none;
	transition: all .3s ease-in-out;
	width: 50%;
	height: 100%;
	z-index: 1200;

	@include media-breakpoint-down(sm){
		width: 100%;
	}

	&.is-active{
		opacity: 1;
		display: inherit;
	}

	ul.menu-wrapper{
    	margin-top: 240px;
    	margin-left: 130px;
    	padding-left: 45px;	

    	@include media-breakpoint-down(sm){
			margin-top: 230px;
    		margin-left: 0;
    		padding-left: 45px;    		
    	}
	}

	li{
		list-style-type: none;

		a{
			color: $white;
			text-transform: uppercase;
			font-size: 25px;
			font-weight: 700;

			position: relative;

	 		&:before{
				content: '';
				position: absolute;
				background-image: url('images/arrow-white.svg');
				background-size: 100%;
				width: 10px;
			    height: 9px;
				left: -15px;
    			top: 23px;
	 		}	
		}

		ul{
			//padding-left: 0;

			li a{
				text-transform: inherit;
				font-size: 18px;
				font-weight: 500;

				&:before{
					display: none;
				}
			}
		}
	}
}

.nav-wrapper{
	margin-top: 4rem;
}


/**
 * Mobile only
 **/

 .hdr-logo-link{
	&.navbar-brand{
		float: right;
	}

 	img{
 		@include media-breakpoint-up(md){
			margin-left: 70px;
 		}
 	
		@include media-breakpoint-down(sm){
			width: 100%;
		}
 	}

 }

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
} 

