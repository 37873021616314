/**
 * Projecten
 **/

 .projecten{
 	img{
 		width: 100%;
 	}
 }

 .project{
 	margin-bottom: 2rem;
	display: none;
	
 	&.reveal{
 		display: inherit;
 	}
 }

 .page-projecten{
 	h1{
 		color: #383838;
 		text-transform: uppercase;
 		font-weight: 700;
 		font-size: 40px;
 		line-height: 38px;
 		margin-left: 25px;
 	}
	
	.projecten__cats{
		margin-top: 3rem;
		margin-bottom: 4rem;
	}

 	ul{
 		text-align: center;
 		max-width: 540px;
 		margin: 0 auto;

 		li{
 			display: inline;
 			list-style-type: none;
 			margin-right: 50px;

 			a{
 				color: #383838;
 				font-size: 20px;
 				text-decoration: underline;
 				font-weight: 400;
 			}
 		}
 	}
 }

 .slideshow{
 	img{
 		width: 100%;
 	}
 }