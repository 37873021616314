/**
 * Standaardpagina
 **/

.page-template-default,
.single-format-standard,
.single-project{
	h1{
	 	color: #383838;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 40px;
	    line-height: 38px;
	}

	h2{
	 	color: #383838;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 32px;
	    line-height: 32px;
	    margin-top: 2.5rem;
	    margin-bottom: 1.5rem;
	}

	.content-wrapper{
		ul{
			margin-left: 0;
			padding-left: 15px;
		}

		li{
			margin-bottom: 10px;
		}
	}

	p strong{
		font-size: 18px;
	}
}