/**
 * Contact
 **/

 .contact-background{
	background-image: url('images/content-background.jpg');
	background-size: cover;
 	padding: 35px;

 	a{
 		color: $white;

 		&:hover{
 			text-decoration: none;
 		}
 	}

 	ul{
		position: relative;

 		&:before{
			content: '';
			position: absolute;
			background-image: url('images/arrow-white.svg');
			background-size: 100%;
			width: 15px;
		    height: 14px;
		    left: 15px;
		    top: 10px;
 		}		

 		li{
 			list-style-type: none;
 		}

 		li,
 		a{
 			color: $white;
 		}

 		strong{
			font-size: 20px;
			text-transform: uppercase;
 		}
 	}
 }

 .contact-form-wrapper{

	h1{
		text-transform: uppercase;
		font-weight: 700;
	}
 }

 .contact-form-content{
 	margin-left: 50px;
 }


 /**
  * Forms
  **/

 form{
	border: 2px solid $black;
	padding: 35px;

	input,
	textarea{
		width: 100%;
		font-size: 20px;
		font-weight: 500;
	}

 	input{
 		border: 0;
 		border-bottom: 1px solid $black;
 	}

 	input[type="submit"]{
 		border: 0;
 		text-decoration: underline;
 		font-size: 16px;
 		text-align: right;
 	}

	textarea{
		border: 0;
		background: -webkit-linear-gradient(bottom, #000 1px, #fff 1px, #fff);
		background: -o-linear-gradient(bottom, #000 1px, #fff 1px, #fff);
		background: linear-gradient(to top, #000 1px, #fff 1px, #fff);
		background-size: 100% 45px;
		padding: 25px;
		padding-left: 0;
		padding-top: 0;
		margin-top: 15px;
		line-height: 45px;
	}
 }


 ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $black;
}