/**
 * Footer
 **/

#footer{
	margin-top: 4rem;	
}

 #footer .container .row{
 	border-top: 2px solid #000;
 	padding-top: 2rem;

	ul{
		padding-left: 0;
	}
	
 	ul li{
 		list-style-type: none;
 		font-weight: 600;

 		a{
 			color: #000;
 			font-weight: 600;
 		}
 	}
 } 