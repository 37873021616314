/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #7FB48F;
$secondary-color: #406195;
$third-color: #833574;
$fourth-color: #C7D069;

$white: #FFFFFF;
$black: #000000;