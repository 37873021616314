/**
 * Subpage
 **/

 .subpage{	
	.service-content{
		padding: 45px;
		position: relative;

		&:after{
			content: '';
			position: absolute;
		}
	}

	.service-title{
		h1{
			font-weight: 700;
			font-size: 48px;
			text-transform: uppercase;
			vertical-align: middle;
			margin-top: 150px;
			margin-left: 75px;
		}
	}

 	&--architecten{
		.service-content{
			border: 2px solid $primary-color;

			&:after{
				content: '';
				position: absolute;
				width: 2px;
				height: 100px;
				right: -2px;
				top: calc(50% - 50px);
				background-color: $white;
			}

			p{
				color: $primary-color;
			}
		}

		.service-title{
			h1{
				color: $primary-color;
			}
		}
 	}

 	&--b2b-klanten{
		.service-content{
			border: 2px solid $secondary-color;

			&:before{
				content: '';
				position: absolute;
				width: 50px;
				height: 2px;
				top: -2px;
				left: calc(65% - 25px);
				background-color: $white;				
			}

			&:after{
				content: '';
				position: absolute;
				width: 50px;
				height: 2px;
				top: -2px;
				left: calc(35% - 25px);
				background-color: $white;
			}

			p{
				color: $secondary-color;
			}
		}

		.service-title{
			h1{
				color: $secondary-color;
			}
		}
 	}

 	&--particuliere-klanten{
		.service-content{
			border: 2px solid $third-color;

			&:before{
				content: '';
				position: absolute;
				width: 2px;
				height: 50px;
				left: -2px;
				top: 0;
				background-color: $white;				
			}

			&:after{
				content: '';
				position: absolute;
				width: 2px;
				height: 50px;
				left: -2px;
				bottom: 0;
				background-color: $white;
			}

			p{
				color: $third-color;
			}
		}

		.service-title{
			h1{
				color: $third-color;
			}
		}
 	}

 	&--totale-projecten{
		.service-content{
			border: 2px solid $fourth-color;

			&:before{
				content: '';
				position: absolute;
				width: 2px;
				height: 50px;
				left: -2px;
				top: 0;
				background-color: $white;				
			}

			&:after{
				content: '';
				position: absolute;
				width: 2px;
				height: 50px;
				left: -2px;
				bottom: 0;
				background-color: $white;
			}

			p{
				color: $fourth-color;
			}
		}

		.service-title{
			h1{
				color: $fourth-color;
			}
		}
 	}	
 }

.subpage-call-to-action{
	margin-top: 50px;

	a{
		color: $black;
 		text-decoration: underline;
		position: relative;
	
 		&:before{
			content: '';
			position: absolute;
			background-image: url('images/arrow.svg');
			background-size: 100%;
			width: 10px;
		    height: 9px;
		    left: -15px;
		    top: 10px;
 		}	
	}
}


 /**
  * Projecten
 **/

 .projecten{
	.more-wrapper{
 		margin-top: 3rem;
	}

 	.more-link{
 		color: $black;
 		text-decoration: underline;
		position: relative;
	
 		&:before{
			content: '';
			position: absolute;
			background-image: url('images/arrow.svg');
			background-size: 100%;
			width: 15px;
		    height: 14px;
		    left: -22px;
		    top: 10px;
 		}
 	}
 }

 .projecten-container{
 	border-top: 2px solid $black;
 	margin-top: 4rem;
	padding-top: 4rem;

	 h2{
	 	text-align: center;
	 	text-transform: uppercase;
	 	margin-bottom: 3rem;
	 }
}

.project{
	position: relative;

	h3{
		font-weight: 300;
		position: absolute;
		bottom: 25px;
    	left: 45px;
	}

	a{
		color: $white;		
	}
}

/**
 * Single
 **/

.tease-post{
	img{
		width: 100%;
	}

	h2{
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	h2 a{
		color: $black;
		text-transform: uppercase;
		font-weight: 700;
		text-decoration: none;
		font-size: 32px;
	}

	a{
		color: #1D1D1B;
		text-decoration: underline;
		font-size: 20px;
	}

	margin-bottom: 2rem;
	border-bottom: 2px solid $black;
}
