/**
 * Frontpage
 **/
.home .content-wrapper{
	margin-top: 0rem;
}

.content-wrapper .header-background{
	min-height: 500px;
	background-image: url('images/content-background.jpg');
	background-size: cover;
	display: table;

	@include media-breakpoint-up(md){
		min-height: 800px;
	}

	h1{
		display: table-cell;
		color: #FFF;
		font-size: 64px;
		vertical-align: middle;
		text-align: center;
		line-height: 48px;
		
		span{
			display: block;
			margin-left: 75px;
		}
	}
}

.logo img{
	//margin: 0 auto;
    display: block;
    margin-left: 50px;
}

.header-menu .logo{
	@include media-breakpoint-down(sm){
		display: none;
	}
}

.header-menu{
	padding: 50px !important;
	padding-bottom: 0 !important;
}

.header-menu-inner{
    height: 100%;
    border-bottom: 2px solid #000;	
}

/**
 * Navigation
 **/

.secondary-navigation{
	@include media-breakpoint-up(md){
		margin-left: 182px;
	}

	ul{
		margin-top: 100px;

		@include media-breakpoint-down(sm){
			padding-left: 25px;
			margin-top: 0;
		}

		li{
			list-style-type: none;
			text-transform: uppercase;
			font-size: 24px;
			margin-bottom: 35px;
		}

		a.architecten{
			color: $primary-color;
			position: relative;

			&:before{
				content: '';
				background-image: url('images/icon-architecten.png');
				background-size: 100%;
				width: 35px;
				height: 35px;
				position: absolute;
    			left: -50px;
			}
		}

		a.b2b{
			color: $secondary-color;
			position: relative;

			&:before{
				content: '';
				background-image: url('images/icon-b2bklanten.png');
				background-size: 100%;
				width: 35px;
				height: 35px;
				position: absolute;
    			left: -50px;				
			}
		}

		a.particulier{
			color: $third-color;
			position: relative;

			&:before{
				content: '';
				background-image: url('images/icon-particuliereklanten.png');
				background-size: 100%;
				width: 35px;
				height: 35px;
				position: absolute;
    			left: -50px;					
			}
		}

		a.projecten{
			color: $fourth-color;
			position: relative;

			&:before{
				content: '';
				background-image: url('images/icon-totaleprojecten.png');
				background-size: 100%;
				width: 35px;
				height: 35px;
				position: absolute;
    			left: -50px;	
			}
		}
	}
}

/**
 * Intro content
 **/

.intro-content{
	margin-top: 6rem;

	h2{
		font-weight: 700;
		font-size: 48px;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}
}


/**
 * Call to action
**/

.call-to-action{
	h3{
		font-weight: 300;

		span{
			display: block;
			font-weight: 700;
		}
	}

	a{
		color: #000;
	}
}