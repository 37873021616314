/**
 * Body
**/

body{
	font-family: 'Darker Grotesque', sans-serif;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrapper{
	margin-top: 16rem;
}

button{
	outline: 0 !important;
	border: 0 !important;

	&:hover,
	&:focus,
	&:active{
		outline: 0 !important;
		border: 0 !important;

	}
}